
export const getDecreaseOrIncreaseFactor = (procedureForm, invoicingTissSetting, degreeOfParticipation) => {
    if (!degreeOfParticipation) return 0
    
    
    let quantity = parseInt(procedureForm.quantity || 1)
    let specialParticipation = getSpecialParticipation(degreeOfParticipation, invoicingTissSetting, procedureForm.pathway?.value) / 100
    const specialParticipationRef = specialParticipation
    const normalParticipation = getNormalParticipation(degreeOfParticipation, invoicingTissSetting) / 100
    let rule = `Valor base x Grau de participação x Grau de participação especial x Quantidade`

    if (procedureForm.pathway?.value === '3' && procedureForm.quantity >= 2) {
        return getDifferentWayLogic(specialParticipation, procedureForm[getBaseValue(degreeOfParticipation)], normalParticipation, quantity)
    }

    if (!specialParticipationRef || !procedureForm.pathway?.value || (procedureForm.pathway?.value === '1' && procedureForm.quantity < 2)) {
        rule = `Valor base x Grau de participação x Quantidade`
        specialParticipation = 1
    } 
    
    if (specialParticipationRef && procedureForm.pathway?.value === '1' && procedureForm.quantity >= 2) {
        rule = `Valor base x Grau de participação x Grau de participação especial x 1`
        quantity = 1
    }

    const unitaryValue = Math.round((procedureForm[getBaseValue(degreeOfParticipation)] * normalParticipation) * 100) / 100
    const value = Math.round((unitaryValue * specialParticipation * quantity) * 100) / 100
    return [specialParticipation, unitaryValue, value, rule]
}

const getBaseValue = (degreeOfParticipation) => {
    const values = { '06': 'anesthetic_port' }
    return values[degreeOfParticipation] || 'medical_fee'
}

const getNormalParticipation = (degreeOfParticipation, invoicingTissSetting) => {
    const values = {
        '00': invoicingTissSetting.tiss_participation_percentage.surgeon,
        '01': invoicingTissSetting.tiss_participation_percentage.first_assistant,
        '02': invoicingTissSetting.tiss_participation_percentage.second_assistant,
        '03': invoicingTissSetting.tiss_participation_percentage.third_assistant,
        '04': invoicingTissSetting.tiss_participation_percentage.fourth_assistant,
        '05': invoicingTissSetting.tiss_participation_percentage.instrumentator,
        '06': invoicingTissSetting.tiss_participation_percentage.anesthetist,
        '07': invoicingTissSetting.tiss_participation_percentage.assistant_anesthetist,
        '08': invoicingTissSetting.tiss_participation_percentage.consultant,
        '09': invoicingTissSetting.tiss_participation_percentage.perfusionist,
        '10': invoicingTissSetting.tiss_participation_percentage.pediatrist,
        '11': invoicingTissSetting.tiss_participation_percentage.assistant_sadt,
        '12': invoicingTissSetting.tiss_participation_percentage.clinical,
        '13': invoicingTissSetting.tiss_participation_percentage.intensivist,
    }
    return (values[degreeOfParticipation] || 0)
}

const getSpecialParticipation = (degreeOfParticipation, invoicingTissSetting, pathway) => {
    const type = degreeOfParticipation === '06' ? 'anesthetic_port' : 'honorary'
    switch (pathway) {
        case '1': return invoicingTissSetting.tiss_participation_percentage_special_case[`${type}_bilateral`]
        case '2': return invoicingTissSetting.tiss_participation_percentage_special_case[`${type}_same_way`]
        case '3': return invoicingTissSetting.tiss_participation_percentage_special_case[`${type}_different_way`]
        default: return getNormalParticipation(degreeOfParticipation, invoicingTissSetting)
    }
}

const getDifferentWayLogic = (specialParticipation, baseValue, normalParticipation, quantity) => {
    const rule = `(Valor base x Grau de participação x (Grau de participação especial + 100%)) / 2) x Quantidade`
    specialParticipation += 1
    const unitaryValue = (baseValue * normalParticipation)
    const value = (unitaryValue * specialParticipation / 2) * quantity
    return [specialParticipation, unitaryValue, value, rule]
}
