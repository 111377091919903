<template>
  <b-modal
    id="new-sus-guide-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @show="onShow"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Nova guia</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="6">
          <PatientInput
            required
            v-model="form.patient"
            :error="false"
            @select="onSelectPatient"
            :disabled="false"
          />
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <label for="health_plan">Convênio</label>
            <multiselect
              :loading="loading"
              id="health_plan"
              :showLabels="false"
              v-model="form.healthPlan"
              track-by="id"
              label="label"
              :options="healthPlans"
              @select="selectHealthPlan"
              placeholder="Selecionar"
              class="with-border"
              :disabled="true"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                <span v-if="!form.patient">Selecione um paciente</span>
                <span v-else>Nenhuma opção de convênio com SUS ativo</span>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="(type || '').length === 0">
        <b-col>
          <b-form-group>
            <label for="guide_type">Tipo de guia</label>
            <multiselect
              id="guide_type"
              v-model="form.guide_type"
              :options="guideOptions"
              label="label"
              placeholder="Selecionar"
              class="with-border"
              :show-labels="false"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                <span v-if="form.healthPlan"> Nenhuma opção </span>
                <span v-else> Selecione um convênio </span>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button variant="primary" :disabled="!canSave" @click="createGuide">
          Criar nova guia
          <b-spinner v-if="loadingCreating" small variant="light" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import itemApi from '@items/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { handleGuideSelected } from '@/utils/invoicingSusHelper'
import { getDecreaseOrIncreaseFactor } from '@/components/Sus/utils/valueRules'

export default {
  name: 'NewSusGuideModal',
  props: {
    closeModal: Function,
    patient: Object,
    healthPlan: Object,
    items: Array,
    type: {
      type: String,
      default: '',
    },
    disabledHealthPlan: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    PatientInput: () => import('@/components/General/PatientInput')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      clinicHealthPlan: null,
      healthPlans: [],
      guideOptions: [],
      loading: false,
      loadingCreating: false,
      form: {
        patient: null,
        healthPlan: null,
        guide_type: null
      }
    }
  },
  computed: {
    canSave() {
      return this.form.patient && this.form.healthPlan && this.form.guide_type
    },
  },
  methods: {
    async onShow() {
      this.susGuide = null;
      
      if (this.patient?.id) {
        this.form.patient = this.patient
        await this.getHealthPlans()
      }
      if (this.healthPlan?.id) {
        this.form.healthPlan = this.healthPlan
        await this.getHealthPlanByPatient(this.healthPlan.id)
        await this.getClinicHealthPlan(this.healthPlan.id)
      }
    },
    clearModal() {
      this.form = {
        patient: null,
        healthPlan: null,
        guide_type: null
      }
      this.loading = false
      this.guideOptions = []
      this.healthPlans = []
      this.closeModal()
    },
    async getHealthPlans() {
      this.loading = true
      try {
        const { data } = await this.api.getPatientPlansSusActive(this.form.patient.id, this.clinic.id)

        
          this.healthPlans = data.map(plan => ({
            value: plan.health_plan.id,
            label: plan.health_plan.fantasy_name
          }))

        if(this.healthPlans.length){
          this.form.healthPlan = this.healthPlans[0];
          
          this.getClinicHealthPlan(this.form.healthPlan.id);
        } else {
          this.$toast.error("Este paciente não possui vínculo com o convênio.");
        }

      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async getClinicHealthPlan(healthPlanId) {
      await this.getHealthPlanByPatient(healthPlanId)
      // this.$emit('setClinicHealthPlanToPending', this.clinicHealthPlan)
      this.setGuideTypes()
    },
    setGuideTypes() {
      this.guideOptions = []
      const guideOptions = [
        { label: 'Guia AIH', value: 'aih' },
        { label: 'Guia APAC', value: 'apac'},
        { label: 'Guia BPA', value: 'bpa' },
      ]
      
      this.guideOptions = guideOptions;
    },
    async onSelectPatient(patient) {
      this.healthPlans = []
      this.form.healthPlan = null

      this.guideOptions = []
      this.form.guide_type = null

      const guideOptions = [
        { label: 'Guia AIH', value: 'aih' },
        { label: 'Guia APAC', value: 'apac'},
        { label: 'Guia BPA', value: 'bpa' },
      ]

      if(this.type) this.form.guide_type = guideOptions.find(item => item.value === this.type.toLowerCase());

      if (!patient) {
        return
      }
      await this.getHealthPlans()
    },
    async selectHealthPlan(healthPlan) {
        this.guideOptions = []
        this.form.healthPlan = healthPlan
        await this.getClinicHealthPlan(healthPlan.value)
    },
    async updateHealthPlan() {
      await this.getClinicHealthPlan(this.form.healthPlan.value)
    },
    handleGuide() {
      const modal = handleGuideSelected(this.form.guide_type?.value)
      this.$bvModal.show(modal)
    },
    updateValueByRules(procedureForm, invoicingSusSetting, form) {
      if (!form.degree_of_participation) return
      const [decreaseOrIncreaseFactor, unitaryValue, value] = getDecreaseOrIncreaseFactor(procedureForm, invoicingSusSetting, form.degree_of_participation)
      form.unitary_value = unitaryValue
      form.decrease_or_increase_factor = decreaseOrIncreaseFactor
      form.value = value
      // this.rule = rule
    },
    async createGuide() {
      this.loadingCreating = true
      const susGuide = {
        patient: this.form.patient
      }

      try {
        this.$emit('createGuideSus', { susGuide, clinicHealthPlan: this.clinicHealthPlan })
        this.handleGuide()
      } catch (error) {
        this.$toast.warning(error.message)
      } finally {
        this.loadingCreating = false
      }
    },
    async getItemWithHealthPlanProps(appointmentItem) {
      if(!appointmentItem) return
      try {
        const { data } = await itemApi.getItem(appointmentItem.item_id, this.clinicHealthPlan.id, appointmentItem?.plan_id)
        return data;
      } catch(err) {
        console.log(err);
        this.$toast.error(err.message)
        return null
      }
    },
    async getHealthPlanByPatient(healthPlanId) {
      if (!this.clinic.id || !healthPlanId) return
      try {
        const { data } = await this.api.getClinicHealthPlanByHealthId(this.clinic.id, healthPlanId, false)
        if (!data) {
          this.$toast.warning('Convênio selecionado não vinculado a clínica')
          return
        }
        this.clinicHealthPlan = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    procedureValue(healthPlanProps) {
      if (!healthPlanProps?.id) return 0
      return healthPlanProps.medical_fee + healthPlanProps.operational_cost + healthPlanProps.m2_filme + healthPlanProps.anesthetic_port
    },
  },
}
</script>

<style lang="scss">
#new-sus-guide-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }
  .disabled-description {
    font-size: 14px;
    font-style: italic;
    color: var(--type-active);
  }
}
</style>
